import shopifyGenericDefaults from '../shopify-generic/config.js';

const isProductPage = window.location.pathname.startsWith('/products/');

const tiresFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;
const baseFitmentFields = ['RideType', 'Year', 'Make', 'Model'];
const extraFitmentFields = isProductPage ? ['Submodel'] : [];

const tiresFieldsMetric = ['tire_width', 'tire_aspect', 'tire_rim'];
const tiresFieldsStandard = ['tire_height', 'tire_width', 'tire_rim'];

shopifyGenericDefaults.Widgets.map((w) => {
  if (w.name === 'FilterChips' || w.name === 'Facets' || w.name === 'FacetDialog') {
    if (w.ignoreFields) {
      w.ignoreFields.push('tire_height', 'tire_width', 'tire_rim', 'tire_aspect');
    } else {
      w.ignoreFields = ['tire_height', 'tire_width', 'tire_rim', 'tire_aspect'];
    }
  }

  return w;
});

function responseHandler({ Facets, ...rest }) {
  return { Facets: isProductPage ? Facets : Facets.filter((f) => f.FieldName !== 'Submodel'), ...rest };
}

const baseGetSelection = shopifyGenericDefaults.Widgets.find((w) => w.name === 'RelatedItems').getSelection;
function getSelection(itemData) {
  window.Convermax.isTireRelated =
    itemData.category?.includes('Tires') || tiresFields.some((f) => itemData[f].value);

  return baseGetSelection?.(itemData);
}

const handleSearchBoxSubmit = () => {
  window.document.querySelector('search-popdown details.is-open .search-popdown__toggle')?.click();
  window.document.body.classList.remove('search-opened');
  window.document.body.style.overflow = 'unset';
  window.document.body.style.paddingRight = 'unset';
};

export default {
  includes: ['shopify-generic'],
  responseHandler,
  ...shopifyGenericDefaults,
  collapsibleAnimationTime: 100,
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (widget) =>
        ![
          'HeaderVehicleWidget',
          'HeaderVehicleWidgetStub',
          'SearchBox',
          'SearchBoxDialogButton',
          'Garage',
          'FitmentTable',
          'MobileSingleVehicleGarage',
          'RelatedItems',
        ].includes(widget.name),
    ),
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: { selector: '[data-nav-icons] #cm-search-box', class: 'cm-search-box' },
      template: 'searchBox/dialogButtonAdaptable',
      onSubmit: () => handleSearchBoxSubmit(),
    },
    {
      name: 'FacetBarTiresStandard',
      type: 'FacetBar',
      fields: tiresFieldsStandard,
      minFacetCount: 3,
    },
    {
      name: 'FacetBarTiresMetric',
      type: 'FacetBar',
      fields: tiresFieldsMetric,
      minFacetCount: 3,
    },
    {
      name: 'SearchBoxDialogButton',
      location: '[data-mobile-menu] search-popdown',
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'SearchBoxDialogButtonCompress',
      type: 'SearchBoxDialogButton',
      location: '[data-nav-items-compress] search-popdown',
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 768,
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
      visibleIf: () => !document.querySelector('.product-single__details')?.innerHTML.includes('Fitment:'),
    },
    {
      name: 'Garage',
      location: { insertAfter: '[data-nav-icons] search-popdown' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileSingleVehicleGarage',
      type: 'SingleVehicleGarage',
      location: {
        lastChildOf: '#nav-drawer .mobile-nav',
        class: 'mobile-menu__item mobile-menu__item--level-1 cm_garage__mobile',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'RelatedItems',
      location: '#cm-related-products',
      template: 'product/relatedItems',
      count: window.Convermax.config?.relatedItemsCount || 6,
      getSelection,
    },
  ],
};
